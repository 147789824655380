import React from 'react';
import { Helmet } from 'react-helmet-async';  // Import Helmet
import './Header.css';

const Header = () => {
  return (
    <>
      <Helmet>
        <title>Transform Your Business with Nexgeius</title>
        <meta
          name="description"
          content="Nexgeius provides innovative web and software solutions to transform your business. From concept to completion, we bring your ideas to life."
        />
        <meta
          name="keywords"
          content="Web Development, Software Solutions, Nexgeius, Business Transformation"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.nexgeius.com/" />
      </Helmet>

      <header className="header">
        <div className="header-content">
          <h1>Transforming Your Vision into Reality</h1>
          <p>
            Elevate your business with innovative web and software solutions
            tailored to your needs. From concept to completion, we bring your
            ideas to life.
          </p>
          <a href="/contact" aria-label="Get Started Today">
            <button>Get Started Today</button>
          </a>
        </div>
        <div className="header-graphic">
          {/* Placeholder for a graphic or abstract design */}
          <div className="graphic-circle" role="img" aria-label="Abstract decorative circle"></div>
        </div>
      </header>
    </>
  );
};

export default Header;
