import React from 'react';
import { Helmet } from 'react-helmet-async';  // Import Helmet for SEO
import './About.css';

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Nexgeius</title>
        <meta
          name="description"
          content="Learn about Nexgeius, a Toronto-based digital solutions company offering custom web and software development. Our team helps businesses stand out in the digital landscape."
        />
        <meta
          name="keywords"
          content="About Nexgeius, Digital Solutions, Web Development, Software Development, Custom Solutions, Toronto"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.nexgeius.com/about" />
      </Helmet>

      <div className="about-page">
        {/* Who Are We Section */}
        <section className="who-we-are">
          <div className="text-block">
            <h2>WHO ARE WE?</h2>
            <p className="main-heading">
              Based in Toronto, we create custom digital solutions for the world.
            </p>
            <p className="support-text">
              We help our clients succeed by understanding what they need to stand out
              in the vast digital landscape and creating custom-tailored solutions that fit
              your company best.
            </p>
          </div>
        </section>

        {/* Our Story Section */}
        <section className="our-story">
          <div className="story-block">
            <h2>OUR STORY</h2>
            <p className="story-text">
              Nexgeius was established by a visionary team of entrepreneurs, united by their university roots
              and an in-depth understanding of the digital landscape. As a growing web and software development
              agency, we are committed to delivering exceptional results.
            </p>
            <p className="story-text">
              Our journey has allowed us to partner with numerous esteemed business owners, and we look forward
              to continuing to expand our reach and foster new relationships in the industry.
            </p>
          </div>
        </section>

        {/* Three Perks Section */}
        <section className="three-perks">
          <div className="perks-block">
            <div className="perks-details">
              <h2>Perks of working with our team</h2>

              <div className="perk-item">
                <h3>Cost Effective</h3>
                <p>
                  Saving you money to invest back in the business, and to promote team lunches and coffee chats :)
                </p>
              </div>

              <div className="perk-item">
                <h3>Human Touch</h3>
                <p>
                  Personalized service to ensure that your business solutions feel right for your goals.
                </p>
              </div>

              <div className="perk-item">
                <h3>Quick and Efficient</h3>
                <p>
                  Delivering results swiftly without compromising on quality or detail.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="cta-section">
          <h2>Have a project in mind?</h2>
          <a href="/contact" className="cta-link">Let’s get to work.</a>
        </section>
      </div>
    </>
  );
};

export default About;
