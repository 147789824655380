import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [navActive, setNavActive] = useState(false);

  const handleBurgerClick = () => {
    setNavActive(!navActive);
  };

  const closeMenu = () => {
    setNavActive(false); // Close the menu when a link is clicked
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <h2><Link to="/">Nexgeius</Link></h2> {/* Link to the home page */}
      </div>
      <ul className={`nav-links ${navActive ? 'active' : ''}`}>
        <li><Link to="/" onClick={closeMenu}>Home</Link></li>
        <li><Link to="/services" onClick={closeMenu}>Services</Link></li>
        <li><Link to="/about" onClick={closeMenu}>About</Link></li>
        <li><Link to="/ai" onClick={closeMenu}>AI</Link></li>
        <li><Link to="/portfolio" onClick={closeMenu}>Portfolio</Link></li>
        <li><Link to="/contact" onClick={closeMenu}>Contact</Link></li>
      </ul>
      <div className={`burger ${navActive ? 'open' : ''}`} onClick={handleBurgerClick}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </nav>
  );
};

export default Navbar;
