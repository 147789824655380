import React from 'react';
import { Helmet } from 'react-helmet-async';  // Import Helmet for SEO
import './IntroSection.css';

const IntroSection = () => {
  return (
    <>
      <Helmet>
        <title>Web and Software Development Experts | Nexgeius</title>
        <meta
          name="description"
          content="Nexgeius helps brands and companies thrive in the digital age with expert web and software development. Let's work together to bring your vision to life."
        />
        <meta
          name="keywords"
          content="Web Development, Software Development, Nexgeius, Digital Solutions, Business Growth"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.nexgeius.com/services" />
      </Helmet>

      <section className="intro-section">
        <div className="intro-content">
          <h1>We help brands and companies stand out in the digital age.</h1>
          <p>
            Our expertise in web and software development ensures that your business thrives online.
            Let's work together to bring your vision to life.
          </p>
        </div>
      </section>
    </>
  );
};

export default IntroSection;
