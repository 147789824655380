import React from 'react';
import { Helmet } from 'react-helmet-async';  // Import Helmet for SEO
import './AIPage.css';

const AIPage = () => {
  return (
    <>
      <Helmet>
        <title>AI-Powered Web Development Tool | Nexgeius</title>
        <meta
          name="description"
          content="Revolutionize web development with Nexgeius' AI-powered tool. Build responsive, SEO-optimized websites in minutes without coding skills. Coming soon!"
        />
        <meta
          name="keywords"
          content="AI Web Development, AI Website Builder, AI-Powered Tool, Responsive Web Design, SEO Optimized Web Development, Nexgeius AI"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.nexgeius.com/ai" />
      </Helmet>

      <div className="ai-page">
        <div className="ai-coming-soon">
          <h3>Coming Soon</h3>
          <p>Stay tuned! Our revolutionary AI-powered web development tool is launching soon. Be the first to experience the future of website building.</p>
        </div>
        <div className="ai-hero">
          <h1>Revolutionizing Web Development with AI</h1>
          <p>
            Imagine a world where website creation and design are no longer limited by coding skills. 
            With our cutting-edge AI, we bring that future to you. Our AI engine enables you to build
            beautiful, responsive, and fully-functional websites in minutes – no technical expertise required.
          </p>
          <p>
            This AI-powered feature is designed to streamline the entire process of web development, 
            offering personalized suggestions, layout optimization, and even SEO-friendly design guidance 
            based on your preferences and business needs. 
          </p>
          <p>
            Whether you're an entrepreneur or a creative professional, our AI will empower you to transform 
            your ideas into reality by simply providing inputs. You can easily modify designs, add features, 
            and preview your website – all in real-time.
          </p>
          <h2>Why Choose Our AI?</h2>
          <ul className="ai-benefits">
            <li><strong>Speed:</strong> Generate complex web designs in a matter of minutes.</li>
            <li><strong>Customization:</strong> Tailor every aspect of your website to your brand’s identity.</li>
            <li><strong>Simplicity:</strong> No coding experience needed, just a few clicks.</li>
            <li><strong>SEO & Performance Optimization:</strong> Our AI automatically optimizes for speed, SEO, and user experience.</li>
            <li><strong>Real-time Preview:</strong> Instantly visualize how your website will look on different devices.</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AIPage;
