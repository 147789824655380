import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';  // Import Helmet for SEO
import './ContactForm.css'; 
import { FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa'; 

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    service: 'other',
    budget: '',
    message: '',
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const accessKey = 'c74582d2-c70f-40a1-afb2-43c5d2bc07d2'; 
    const apiUrl = 'https://api.web3forms.com/submit';

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          access_key: accessKey,
          ...formData,
        }),
      });

      if (response.ok) {
        setStatus('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          service: 'other',
          budget: '',
          message: '',
        });
      } else {
        setStatus('Failed to send message. Please try again.');
      }
    } catch (error) {
      setStatus('Failed to send message. Please try again.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | Nexgeius</title>
        <meta
          name="description"
          content="Get in touch with Nexgeius for your web design, app development, or custom software needs. Fill out our contact form or reach us directly via phone or social media."
        />
        <meta
          name="keywords"
          content="Contact Nexgeius, Web Design, App Development, Software Solutions, Contact Form"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.nexgeius.com/contact" />
      </Helmet>

      <div className="contact-page-container">
        <div className="contact-main-section">
          {/* Left Column - Contact Form */}
          <section className="contact-form-section">
            <form onSubmit={handleSubmit} className="custom-contact-form">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />

              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />

              <label htmlFor="service">Service You Need</label>
              <select
                name="service"
                value={formData.service}
                onChange={handleChange}
                required
              >
                <option value="other">Other</option>
                <option value="web_design">Web Design</option>
                <option value="app_development">App Development</option>
                <option value="software_maintenance">Software Maintenance</option>
              </select>

              <label htmlFor="budget">Budget (Optional)</label>
              <input
                type="text"
                name="budget"
                value={formData.budget}
                onChange={handleChange}
                placeholder="Enter your budget (Optional)"
              />

              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="5"
                required
              ></textarea>

              <button type="submit" className="custom-submit-btn">Let's Talk</button>
              {status && <p className="form-status">{status}</p>}
            </form>
          </section>

          {/* Right Column - Contact Information */}
          <section className="contact-info-section">
            <h2>Let's bring your idea to life!</h2>
            <p>
              We’d love to help you turn your ideas into reality. Whether you’re looking for web design, app development, or custom software solutions, we’re here for you.
            </p>
            <p>Feel free to reach out through the form or contact us directly:</p>
            <p><strong>+1 (647) 373-0304</strong></p>
            <p>Or connect with us on social media:</p>
            <div className="social-links">
              <a href="https://www.facebook.com" aria-label="Facebook"><FaFacebookF /></a>
              <a href="https://www.linkedin.com" aria-label="LinkedIn"><FaLinkedinIn /></a>
              <a href="https://www.instagram.com" aria-label="Instagram"><FaInstagram /></a>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
