import React from 'react';
import './Services.css';
import { Helmet } from 'react-helmet-async';

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Our Services | Nexgeius</title>
        <meta
          name="description"
          content="Explore our range of services including website development, software development, and web & software maintenance. At Nexgeius, we bring your ideas to life with tailored digital solutions."
        />
        <meta
          name="keywords"
          content="Website Development, Software Development, Web Maintenance, Software Maintenance, Nexgeius Services"
        />
        <link rel="canonical" href="https://www.nexgeius.com/services" />
      </Helmet>

      <section className="services" id="services">
        <div className="services-intro">
          <button className="services-button">Our Services</button>
          <h2>We help your ideas take flight.</h2>
          <p>
            Our team is dedicated to turning your vision into reality with
            innovative solutions tailored to your needs.
          </p>
        </div>
        <div className="service-cards-container">
          <div className="service-cards">
            <div className="service-card">
              <div className="service-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="36px"
                  height="36px"
                >
                  <path d="M12 4.5C16.136 4.5 19.5 7.864 19.5 12S16.136 19.5 12 19.5 4.5 16.136 4.5 12 7.864 4.5 12 4.5m0-1.5C6.755 3 2.25 7.505 2.25 12.75S6.755 22.5 12 22.5 21.75 18 21.75 12.75 17.245 3 12 3zm1.5 15.75h-3v-1.5h3v1.5zm2.25-4.125H8.25V12h7.5v2.625zM13.5 12v-4.5h-3v4.5h3z" />
                </svg>
              </div>
              <h3>Website Development</h3>
              <p>
                Creating responsive and visually appealing websites tailored to
                your needs.
              </p>
              <a href="/contact" className="learn-more">Learn More &rarr;</a>
            </div>
            <div className="service-card">
              <div className="service-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="36px"
                  height="36px"
                >
                  <path d="M6 8.25h12v1.5H6v-1.5zM6 11.25h12v1.5H6v-1.5zM6 14.25h12v1.5H6v-1.5zM3 6.75h18v10.5H3V6.75zm0-1.5C2.585 5.25 2.25 5.585 2.25 6v12c0 .415.335.75.75.75h18c.415 0 .75-.335.75-.75V6c0-.415-.335-.75-.75-.75H3z" />
                </svg>
              </div>
              <h3>Software Development</h3>
              <p>
                Developing custom software solutions that address your unique
                business challenges.
              </p>
              <a href="/contact" className="learn-more">Learn More &rarr;</a>
            </div>
            <div className="service-card">
              <div className="service-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="36px"
                  height="36px"
                >
                  <path d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm0 1.5c4.552 0 8.25 3.698 8.25 8.25 0 .527-.058 1.04-.158 1.536l-6.828-4.866c-.557-.396-1.22-.615-1.916-.615-.696 0-1.359.219-1.916.615L3.908 15.286c-.1-.496-.158-1.009-.158-1.536 0-4.552 3.698-8.25 8.25-8.25zM4.983 16.027l6.814-4.854c.557-.397 1.247-.615 1.946-.615.699 0 1.389.218 1.946.615l6.814 4.854c-.387.67-.89 1.268-1.485 1.772l-5.423-3.866c-.896-.638-2.01-.97-3.147-.97s-2.251.332-3.147.97l-5.423 3.866c-.595-.504-1.098-1.102-1.485-1.772z" />
                </svg>
              </div>
              <h3>Web & Software Maintenance</h3>
              <p>
                Hosting & Management, Optimization, and frequent updates to keep
                your applications running smoothly.
              </p>
              <a href="/contact" className="learn-more">Learn More &rarr;</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
