import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { FaStar } from 'react-icons/fa';
import { Helmet } from 'react-helmet-async';  // Import Helmet for SEO
import './ReviewSection.css';

const reviews = [
  {
    text: "They were proactive in addressing our needs and promptly responded to any concerns or inquiries we had. With Canadian Software Agency’s help, we increased online visibility, web traffic, and qualified leads.",
    author: "Debra Cafaro",
    role: "Chairman & CEO, Vintas",
  },
  {
    text: "Canadian Software Agency was an excellent partner in bringing our vision to life! They managed to strike the right balance between aesthetics and functionality, ensuring that the end product was not only visually appealing but also practical and usable.",
    author: "Jackie Philbin",
    role: "Director – Nutrition for Lifestyle",
  },
  {
    text: "They met expectations, and we’ve seen an increase in downloads and monthly users. Our business doubled from this new product line. Canadian Software Agency was ahead of schedule with deliverables — turnaround time was about 48 hours. They were passionate and efficient about their work and transformed the client’s vision into a viable product.",
    author: "Tariehk",
    role: "VP of Marketing, OSI Affiliate",
  },
];

const ReviewSection = () => {
  return (
    <>
      <Helmet>
        <title>Client Reviews | Nexgeius</title>
        <meta
          name="description"
          content="Read what our clients say about Nexgeius. From increased online visibility to exceptional software solutions, our clients love us for our commitment to excellence."
        />
        <meta
          name="keywords"
          content="Nexgeius Reviews, Client Testimonials, Software Development Reviews, Customer Satisfaction"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.nexgeius.com/reviews" />
      </Helmet>

      <section className="review-section">
        <div className="review-content">
          <div className="review-text">
            <h2>Why <span className="highlight">our clients</span> love us?</h2>
            <p>
              Our clients love us because we prioritize effective communication and are committed to delivering high-quality software solutions that meet the highest standards of excellence.
            </p>
          </div>
          <div className="swiper-container">
            <Swiper spaceBetween={30} pagination={{ clickable: true }}>
              {reviews.map((review, index) => (
                <SwiperSlide key={index}>
                  <div className="swiper-slide">
                    <p className="review-quote">{review.text}</p>
                    <p className="review-author">{review.author}<br />{review.role}</p>
                    <div className="rating">
                      <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReviewSection;
