import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import Header from './components/Homepage/Header';
import Navbar from './components/Navbar';
import Services from './components/Homepage/Services';
import ServicesPage from './components/Servicepage/ServicesPage';
import PortfolioPage from './components/PortfolioPage';
import TestimonialsPage from './components/TestimonialsPage'; 
import BlogPage from './components/BlogPage'; 
import ContactForm from './components/Contactpage/ContactForm';
import Technologies from './components/Homepage/Technologies';
import Process from './components/Homepage/Process';
import Footer from './components/Homepage/Footer';
import About from './components/Aboutpage/About';
import AIPage from './components/Aipage/AIPage';
import Contact from './components/Homepage/Contact';
import NotFound from './components/NotFound';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<>
              <Header />
              <Services />
              <Process />
              <Technologies />
              <Contact />
            </>} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/ai" element={<AIPage />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
