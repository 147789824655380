import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';  // Import Helmet for SEO
import './NotFound.css';

function NotFound() {
  return (
    <>
      <Helmet>
        <title>404 Page Not Found | Nexgeius</title>
        <meta
          name="description"
          content="Oops! It looks like you've ventured into unknown territory. Use this page to find your way back to Nexgeius' homepage."
        />
        <meta
          name="robots"
          content="noindex, follow"  // Use noindex to avoid indexing error pages
        />
        <link rel="canonical" href="https://www.nexgeius.com/404" />
      </Helmet>

      <div className="not-found-container">
        <div className="starry-bg">
          <h1 className="not-found-title">Oops! You’ve ventured into the unknown...</h1>
          <p className="not-found-text">
            Looks like you’ve stumbled into a black hole, or maybe Narnia? <span role="img" aria-label="black-hole">🌌</span>
          </p>
          <div className="not-found-emoji">
            <span role="img" aria-label="ghost">👻</span> Boo! You're lost! 
            <span role="img" aria-label="shrug">🤷</span>
          </div>
          <p className="not-found-help">
            Let’s get you back to reality. 
            <Link to="/" className="home-link"> Click here</Link> to return home and avoid any more space-time mishaps!
          </p>
          <p className="not-found-adventure">Or... keep wandering if you’re feeling adventurous, but we warned you! 🚀</p>
          <div className="shooting-stars"></div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
