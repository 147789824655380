import React from 'react';
import './TechnologyGrid.css';

const TechnologyGrid = ({ items }) => {
  return (
    <div className="technology-grid">
      {items.map((item, index) => (
        <div className="technology-item" key={index}>
          <img src={item.icon} alt={item.name} className="tech-icon" />
          <p>{item.name}</p>
        </div>
      ))}
    </div>
  );
};

export default TechnologyGrid;
