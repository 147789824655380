import React from 'react';
import { Helmet } from 'react-helmet-async';  // Import Helmet for SEO
import './Process.css';

const Process = () => {
  return (
    <>
      <Helmet>
        <title>Our Process | Nexgeius - From Idea to Launch</title>
        <meta
          name="description"
          content="At Nexgeius, we follow a structured process to bring your ideas to life, from discovery and planning to development and support. Learn about our four-step process."
        />
        <meta
          name="keywords"
          content="Nexgeius Process, Software Development Process, Web Development Process, Idea to Launch"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.nexgeius.com/process" />
      </Helmet>

      <section className="process" id="process">
        <div className="process-header">
          <button className="process-button">OUR Process</button>
          <h2>Bringing Your Ideas to Life</h2>
        </div>
        <div className="steps">
          <div className="step">
            <div className="step-content">
              <div className="circle">01</div>
              <h3>Discovery</h3>
              <p>We understand your needs and envision the best solutions.</p>
            </div>
            <div className="arrow">→</div>
          </div>
          <div className="step">
            <div className="step-content">
              <div className="circle">02</div>
              <h3>Planning</h3>
              <p>We create a detailed plan to turn your vision into reality.</p>
            </div>
            <div className="arrow">→</div>
          </div>
          <div className="step">
            <div className="step-content">
              <div className="circle">03</div>
              <h3>Development</h3>
              <p>Our team brings the plan to life with precise execution.</p>
            </div>
            <div className="arrow">→</div>
          </div>
          <div className="step">
            <div className="step-content">
              <div className="circle">04</div>
              <h3>Launch & Support</h3>
              <p>We deploy the solution and provide ongoing support.</p>
            </div>
            <div className="arrow">→</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Process;
