import React from 'react';
import { Helmet } from 'react-helmet-async';  // Import Helmet for SEO
import './Portfolio.css';

const Portfolio = () => {
  return (
    <>
      <Helmet>
        <title>Our Portfolio | Nexgeius</title>
        <meta
          name="description"
          content="Nexgeius is updating its portfolio to showcase our latest projects. Please check back soon to see the innovative work we've done for our clients."
        />
        <meta
          name="keywords"
          content="Nexgeius Portfolio, Web Development Projects, Software Development Portfolio, Latest Projects"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.nexgeius.com/portfolio" />
      </Helmet>

      <section className="portfolio-maintenance" id="portfolio">
        <div className="maintenance-message">
          <h1>Our Portfolio is Under Maintenance</h1>
          <p>We're working hard to update this section. Please check back soon!</p>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
