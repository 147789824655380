import React from 'react';
import { Helmet } from 'react-helmet-async';  // Import Helmet for SEO
import './Contact.css';

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Nexgeius - Custom Digital Solutions</title>
        <meta
          name="description"
          content="Have a project in mind? Contact Nexgeius to get started with innovative web and software solutions. Let's bring your vision to life."
        />
        <meta
          name="keywords"
          content="Contact Nexgeius, Web Solutions, Software Development, Get Started, Project Consultation"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.nexgeius.com/contact" />
      </Helmet>

      <section className="contact" id="contact">
        <div className="cta">
          <h3>Have a project in mind?</h3>
          <a href="/contact" className="cta-link">Let’s get to work.</a>
        </div>
      </section>
    </>
  );
};

export default Contact;
