import React from 'react';
import { Helmet } from 'react-helmet-async';  // Import Helmet for SEO
import './ServiceList.css';

const services = [
  { title: "Web Design and Development", description: "Creating responsive and visually appealing websites tailored to your needs.", icon: "https://www.svgrepo.com/show/1699/web-development.svg" },
  { title: "Mobile App Development", description: "Developing powerful mobile apps for iOS and Android to help you reach your audience on the go.", icon: "https://cdn-icons-png.flaticon.com/512/1085/1085828.png" },
  { title: "E-commerce Solutions", description: "Building robust online stores that drive sales and offer seamless shopping experiences.", icon: "https://www.svgrepo.com/show/65849/e-commerce-solutions.svg" },
  { title: "Digital Marketing", description: "Helping you grow your brand online with targeted marketing strategies and campaigns.", icon: "https://static.thenounproject.com/png/3315575-200.png" },
  { title: "SEO Optimization", description: "Boosting your website’s visibility on search engines to attract more traffic.", icon: "https://cdn-icons-png.flaticon.com/512/3107/3107969.png" },
  { title: "Branding and Identity", description: "Crafting a strong, consistent brand identity that resonates with your audience.", icon: "https://uxwing.com/wp-content/themes/uxwing/download/seo-marketing/brand-identity-icon.png" },
  { title: "Custom Software Development", description: "Developing custom software solutions that address your unique business challenges.", icon: "https://static.thenounproject.com/png/581062-200.png" },
  { title: "UI/UX Design", description: "Designing user interfaces and experiences that are intuitive and engaging.", icon: "https://uxwing.com/wp-content/themes/uxwing/download/arts-graphic-shapes/website-ui-ux-icon.png" },
  { title: "Cloud Services", description: "Offering cloud solutions that ensure scalability, flexibility, and security for your business.", icon: "https://www.svgrepo.com/show/262480/clouds-cloud.svg" }
];

const ServiceList = () => {
  return (
    <>
      <Helmet>
        <title>Our Services | Nexgeius</title>
        <meta
          name="description"
          content="Explore a wide range of services offered by Nexgeius, including web development, mobile app development, SEO, branding, and more to bring your ideas to life."
        />
        <meta
          name="keywords"
          content="Web Development, Mobile App Development, E-commerce Solutions, Digital Marketing, SEO Optimization, Branding, Custom Software, UI/UX Design, Cloud Services"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.nexgeius.com/services" />
      </Helmet>

      <section className="service-list">
        <div className="intro-text">
          <h2>We are pioneers in Software, Web, and App development.</h2>
        </div>
        <div className="service-cards">
          {services.map((service, index) => (
            <div className="service-card" key={index}>
              <img src={service.icon} alt={service.title} className="service-icon" />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default ServiceList;
