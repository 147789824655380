import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';  // Import Helmet for SEO
import TechnologyGrid from './TechnologyGrid';
import './TechnologyList.css';

const tabs = [
  { name: 'Web', key: 'web' },
  { name: 'UI/UX Design', key: 'uiux' },
  { name: 'Testing', key: 'testing' },
  { name: 'Mobile', key: 'mobile' },
  { name: 'Database', key: 'database' },
  { name: 'Blockchain', key: 'blockchain' },
];

const technologyData = {
  web: [
    { name: 'HTML', icon: 'https://upload.wikimedia.org/wikipedia/commons/6/61/HTML5_logo_and_wordmark.svg' },
    { name: 'CSS 3', icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d5/CSS3_logo_and_wordmark.svg' },
    { name: 'JavaScript', icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png' },
    { name: 'WebRTC', icon: 'https://webrtc.github.io/webrtc-org/assets/images/webrtc-logo-vert-retro-255x305.png' },
    { name: 'PHP', icon: 'https://upload.wikimedia.org/wikipedia/commons/2/27/PHP-logo.svg' },
    { name: 'WordPress', icon: 'https://upload.wikimedia.org/wikipedia/commons/0/09/Wordpress-Logo.svg' },
    { name: 'Laravel', icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Laravel.svg' },
    { name: 'Bootstrap', icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b2/Bootstrap_logo.svg' },
    { name: 'Shopify', icon: 'https://www.svgrepo.com/show/303503/shopify-logo.svg' },
  ],
  uiux: [
    { name: 'Adobe After Effects', icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhIK-7_lyk30en7B-aQUdFZJdiatTfCAu6LQ&s' },
    { name: 'Adobe Illustrator', icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fb/Adobe_Illustrator_CC_icon.svg' },
    { name: 'Adobe Photoshop', icon: 'https://upload.wikimedia.org/wikipedia/commons/a/af/Adobe_Photoshop_CC_icon.svg' },
    { name: 'Adobe XD', icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c2/Adobe_XD_CC_icon.svg' },
    { name: 'Figma', icon: 'https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg' },
  ],
  testing: [
    { name: 'Appium', icon: 'https://cdn.worldvectorlogo.com/logos/appium.svg' },
    { name: 'Jira', icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8a/Jira_Logo.svg/2560px-Jira_Logo.svg.png' },
    { name: 'JMeter', icon: 'https://jmeter.apache.org/images/jmeter_square.png' },
    { name: 'Postman', icon: 'https://www.svgrepo.com/show/354202/postman-icon.svg' },
    { name: 'Selenium', icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d5/Selenium_Logo.png' },
    { name: 'Trello', icon: 'https://upload.wikimedia.org/wikipedia/en/archive/8/8c/20210216184933%21Trello_logo.svg' },
  ],
  mobile: [
    { name: 'Flutter', icon: 'https://upload.wikimedia.org/wikipedia/commons/1/17/Google-flutter-logo.png' },
    { name: 'Android', icon: 'https://upload.wikimedia.org/wikipedia/commons/3/3e/Android_logo_2019.png' },
    { name: 'iOS', icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ca/IOS_logo.svg' },
    { name: 'Java', icon: 'https://upload.wikimedia.org/wikipedia/en/3/30/Java_programming_language_logo.svg' },
    { name: 'Kotlin', icon: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Kotlin_Icon.png' },
    { name: 'React Native', icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg' },
    { name: 'Swift', icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9d/Swift_logo.svg' },
    { name: 'XCode', icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1e/Xcode_Icon.png' },
  ],
  database: [
    { name: 'MongoDB', icon: 'https://upload.wikimedia.org/wikipedia/commons/9/93/MongoDB_Logo.svg' },
    { name: 'MySQL', icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/MySQL_textlogo.svg' },
    { name: 'PostgreSQL', icon: 'https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg' },
    { name: 'SQLite', icon: 'https://upload.wikimedia.org/wikipedia/commons/3/38/SQLite370.svg' },
  ],
  blockchain: [
    { name: 'Bitcoin', icon: 'https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg' },
    { name: 'Ethereum', icon: 'https://upload.wikimedia.org/wikipedia/commons/0/05/Ethereum_logo_2014.svg' },
    { name: 'Metamask', icon: 'https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg' },
    { name: 'Solidity', icon: 'https://upload.wikimedia.org/wikipedia/commons/9/98/Solidity_logo.svg' },
  ],
};

const TechnologyList = () => {
  const [activeTab, setActiveTab] = useState('web');

  return (
    <>
      <Helmet>
        <title>Technologies We Use | Nexgeius</title>
        <meta
          name="description"
          content="Discover the wide range of tools and technologies used by Nexgeius, from web development and mobile solutions to blockchain and database management."
        />
        <meta
          name="keywords"
          content="Web Technologies, UI/UX Design, Mobile Technologies, Database, Blockchain, Nexgeius"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.nexgeius.com/technologies" />
      </Helmet>

      <section className="technology-list">
        <h2>Our Tools & Technologies</h2>
        <div className="technology-tabs">
          {tabs.map((tab) => (
            <button
              key={tab.key}
              className={activeTab === tab.key ? 'active' : ''}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <TechnologyGrid items={technologyData[activeTab]} />
      </section>
    </>
  );
};

export default TechnologyList;
