import React from 'react';
import { Helmet } from 'react-helmet-async';  // Import Helmet for SEO
import IntroSection from '../IntroSection';
import ServiceList from './ServiceList';
import TechnologyList from './TechnologyList';
import ReviewSection from './ReviewSection';

const ServicesPage = () => {
  return (
    <>
      <Helmet>
        <title>Our Services | Nexgeius</title>
        <meta
          name="description"
          content="Explore the wide range of services Nexgeius offers, including web development, software development, and more. Let us help bring your ideas to life with our innovative solutions."
        />
        <meta
          name="keywords"
          content="Nexgeius Services, Web Development, Software Development, Technology Solutions"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.nexgeius.com/services" />
      </Helmet>

      <div className="services-page">
        <IntroSection />
        <ServiceList />
        <TechnologyList />
        <ReviewSection />
      </div>
    </>
  );
};

export default ServicesPage;
